const DocMenuConfig = [
  {
    heading: "dashboard",
    route: "/dashboard",
    pages: [
      {
        heading: "dashboard",
        route: "/dashboard",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
      /*
      {
        heading: "layoutBuilder1",
        route: "/builder",
        svgIcon: "media/icons/duotune/general/gen019.svg",
        fontIcon: "bi-layers",
      },
       */
    ],
  },


  {
    heading: "Satış/Operasyon",
    route: "/crafted",
    pages: [
      {
        sectionTitle: "Rezervasyonlar",
        route: "/reservations",
        svgIcon: "media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
        sub: [
            /*
          {
            heading: "Yeni Ekle",
            route: "/apps/sys/reservations/addnew",
          },
             */
          {
            heading: "Yeni Ekle",
            route: "/apps/sys/reservations/makereservation",
          },
        /*  {
            heading: "Rezervasyon Detayları",
            route: "/apps/sys/reservations/resDetail",
          },*/
          {

            heading: "Tüm Rezervasyonlar ",

            route: "/apps/sys/reservations/list",
          },
          {
            heading: "Ürün Rezervasyonları",
            route: "/apps/sys/reservations/productlist",
          },
          /*{
            sectionTitle: "Rezervasyonlar",
            sub: [
              {
                heading: "Tüm Rezervasyonlar",
                route: "/apps/sys/reservations/list",
              },
              {
                heading: "Ürün Rezervasyonları",
                route: "/apps/sys/reservations/productlist",
              },
            ],

          },*/
          {
            heading: "Konfirme Listesi",
            route: "/apps/sys/reservations/confirmlist",
          },
          {
            heading: "Bilet Rez.",
            route: "/apps/sys/reservations/listflight",
          },
        ],
      },
      {
        sectionTitle: "Tahsilatlar",
        route: "/collection",
        svgIcon: "media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
        sub: [
          {
            heading: "Tahsilatlar",
            route: "/apps/sys/collections/list",
          },
          {
            heading: "Tahsilat Takibi",
            route: "/apps/sys/collections/traking",
          },
        ],
      },
      {
        sectionTitle: "Operasyon",
        route: "/transport",
        svgIcon: "media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
        sub: [
          {
            heading: "Konfirme Takibi",
            route: "/apps/sys/hotels/list",
          },
          {
            heading: "Transfer Operasyon",
            route: "/apps/sys/operation/transferoperation",
          },
          {
            heading: "Transfer Firmaları",
            route: "/apps/sys/operation/transfer_company",
          },
          {
            heading: "Transfer Bölgeleri",
            route: "/apps/sys/operation/transfer_area",
          },
          {
            heading: "Transferler",
            route: "/apps/sys/operation/transfers",
          },
          {
            heading: "Kültür Turu",
            route: "/apps/sys/hotels/list",
          },
          {
            heading: "Yurtiçi / Yurtdışı / Gemi",
            route: "/apps/sys/hotels/list",
          },
        ],
      },


      {
        sectionTitle: "MICE",
        route: "/mice",
        svgIcon: "media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
        sub: [
          {
            heading: "Gelen Talep",
            route: "/apps/sys/mice/requests/list",
          },
          {
            heading: "Opsiyon Takibi",
            route: "/apps/sys/hotels/list",
          },
          {
            heading: "Operasyon",
            route: "/apps/sys/hotels/list",
          },
          {
            heading: "Fiyat Listleri",
            route: "/apps/sys/mice/pricelists/list",
          },
          {
            heading: "Fiyat Listesi Detayları",
            route: "/apps/sys/mice/pricelists/items",
          },
        ],
      },


    ],
  },
  {
    heading: "Ürünler",
    route: "/crafted",
    pages: [
      {
        sectionTitle: "Oteller",
        route: "/hotels",
        svgIcon: "media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
        sub: [
          {
            heading: "Otel Ekle",
            route: "/apps/sys/hotels/addnew",
          },
          {
            heading: "Otel Listesi",
            route: "/apps/sys/hotels/list",
          },
          {
            heading: "Tedarikçiler",
            route: "/apps/sys/hotels/list",
          },
          {
            heading: "Otel Paket",
            route: "/apps/sys/hotels/list",
          },
        ],
      },
      {
        sectionTitle: "Uçaklar",
        route: "/flight",
        svgIcon: "media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
        sub: [
          {
            heading: "Havayolları",
            route: "/apps/sys/flight/airline",
          },
          {
            heading: "Tedarikçiler",
            route: "/apps/sys/flight/suppliers",
          },
          {
            heading: "Charter/Anex",
            route: "/apps/sys/hotels/list",
          },
        ],
      },
      {
        sectionTitle: "Transfer",
        route: "/transport",
        svgIcon: "media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
        sub: [
          {
            heading: "Transferler",
            route: "/apps/sys/hotels/list",
          },
          {
            heading: "Tedarikçiler",
            route: "/apps/sys/hotels/list",
          },
          {
            heading: "Transfer Bölgeleri",
            route: "/apps/sys/hotels/list",
          },
        ],
      },
      {
        sectionTitle: "Kültür Turları",
        route: "/culturetour",
        svgIcon: "media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
        sub: [
          {
            heading: "Turlar",
            route: "/apps/sys/hotels/list",
          },
          {
            heading: "Tedarikçiler",
            route: "/apps/sys/hotels/list",
          },
        ],
      },
      {
        sectionTitle: "Yurtiçi Turları",
        route: "/localtour",
        svgIcon: "media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
        sub: [
          {
            heading: "Turlar",
            route: "/apps/sys/hotels/list",
          },
          {
            heading: "Tedarikçiler",
            route: "/apps/sys/hotels/list",
          },
        ],
      },
      {
        sectionTitle: "Yurtdışı Turları",
        route: "/abroadtour",
        svgIcon: "media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
        sub: [
          {
            heading: "Turlar",
            route: "/apps/sys/hotels/list",
          },
          {
            heading: "Tedarikçiler",
            route: "/apps/sys/hotels/list",
          },
        ],
      },
      {
        sectionTitle: "Gemi Turları",
        route: "/cruise",
        svgIcon: "media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
        sub: [
          {
            heading: "Turlar",
            route: "/apps/sys/hotels/list",
          },
          {
            heading: "Tedarikçiler",
            route: "/apps/sys/hotels/list",
          },
        ],
      },
      {
        sectionTitle: "Kiralık Araç",
        route: "/rentacar",
        svgIcon: "media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
        sub: [
          {
            heading: "Araçlar",
            route: "/apps/sys/hotels/list",
          },
          {
            heading: "Tedarikçiler",
            route: "/apps/sys/hotels/list",
          },
        ],
      },
    ],
  },
  {
    heading: "Cari Hesaplar",
    route: "/crafted",
    pages: [
      {
        sectionTitle: "Cari Hesaplar",
        route: "/account",
        svgIcon: "media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
        sub: [
          {
            heading: "Hesap Ekle",
            route: "/apps/sys/account/addnew",
          },
          {
            heading: "Hesap Listesi",
            route: "/apps/sys/account/list",
          },
          {
            heading: "Hesap Grupları",
            route: "/apps/sys/account/accountgrouplist",
          },
        ],
      },
      {
        sectionTitle: "Satış/Pazarlama",
        route: "/rezpayment",
        svgIcon: "media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
        sub: [
          {
            heading: "Görüşme Raporları",
            route: "/apps/sys/hotels/list",
          },
          {
            heading: "Belgeler",
            route: "/apps/sys/hotels/list",
          },
        ],
      },
    ],
  },
  {
    heading: "Şirketler",
    route: "/crafted",
    pages: [
      {
        sectionTitle: "Şirketler",
        route: "/rezpayment",
        svgIcon: "media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
        sub: [
          {
            heading: "Şirketler",
            route: "/apps/sys/hotels/list",
          },
        ],
      },
      {
        sectionTitle: "Kullanıcılar",
        route: "/user",
        svgIcon: "media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
        sub: [
          {
            heading: "Kullanıcı Ekle",
            route: "/apps/sys/user/addnew",
          },
          {
            heading: "Kullanıcı Listesi",
            route: "/apps/sys/user/list",
          },
          {
            heading: "Yetilendirme",
            route: "/apps/sys/user/list",
          },
          {
            heading: "Talepler",
            route: "/apps/sys/user/list",
          },
        ],
      },

    ],
  },
  {
    heading: "Muhasebe",
    route: "/crafted",
    pages: [
      {
        sectionTitle: "Faturalar",
        route: "/rezpayment",
        svgIcon: "media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
        sub: [
          {
            heading: "Faturalandırılmamış Ürünler",
            route: "/apps/sys/accounting/reservationNoninvoice",
          },
          {
            heading: "Faturalar",
            route: "/apps/sys/accounting/invocies",
          },
          {
            heading: "Tedarikçi Faturaları",
            route: "/apps/sys/hotels/list",
          },
        ],
      },
      {
        sectionTitle: "Tahsilat/Ödeme",
        route: "/user",
        svgIcon: "media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
        sub: [
          {
            heading: "Tahsilat Takibi",
            route: "/apps/sys/user/addnew",
          },
          {
            heading: "Ödeme Takibi",
            route: "/apps/sys/user/list",
          },
          {
            heading: "Cari Takibi",
            route: "/apps/sys/user/list",
          },
        ],
      },
      {
        sectionTitle: "Ödeme Yöntemleri",
        route: "/paymentmethod",
        svgIcon: "media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
        sub: [
          {
            heading: "Bankalar",
            route: "/apps/sys/payment-methods/list",
          },
          {
            heading: "Banka Hesapları",
            route: "/apps/sys/payment-methods/bank_accounts",
          },
          {
            heading: "Poslar",
            route: "/apps/sys/payment-methods/bank_pos",
          },
          {
            heading: "Tedarikçiler",
            route: "/apps/sys/payment-methods/suppliers",
          },
        ],
      },
      {
        sectionTitle: "Logo Kodları",
        route: "/logocodes",
        svgIcon: "media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
        sub: [
          {
            heading: "KDV Oranları",
            route: "/apps/sys/logo-codes/vat_rates",
          },
          {
            heading: "Masraf Merkezi",
            route: "/apps/sys/logo-codes/cost_center",
          },
        ],
      },
      {
        sectionTitle: "Raporlar",
        route: "/reports",
        svgIcon: "media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
        sub: [
          {
            sectionTitle: "Acente",
            sub: [
              {
                heading: "Acente Bazlı Satış Raporu",
                route: "/apps/sys/reports/agency_based_sales_report",
              },
              {
                heading: "Çıkış Bazlı Aylık Acente Raporu",
                route: "/apps/sys/reports/chechout_based_monthly_agency_sale_report",
              },
            ],

          },
          {
            sectionTitle: "Otel",
            sub: [
              {
                heading: "Otel Bazlı Satış Raporu",
                route: "/apps/sys/reports/hotel_based_sales_report",
              },
            ],

          },

          /*{
            sectionTitle: "Ürün",
            route: "/reports",
            sub: [
              {
                heading: "Ürün Bazlı Satış Raporu",
                route: "/apps/sys/reports/product_based_sales_report",
              },
            ],

          },*/
        ],
      },

    ],
  },
];

export default DocMenuConfig;
